/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo } from "react";
import { loadable } from "shared/helpers/loadableComponent";

import type { WidgetProps, WidgetUnion } from "./types";

const AccessibilityInfo = loadable(() => import("./AccessibilityInfo"));
const Accordion = loadable(() => import("./Accordion"));
const Attributes = loadable(() => import("./Attributes"));
const Button = loadable(() => import("./Button"));
const Columns = loadable(() => import("./Columns"));
const ContactForm = loadable(() => import("./ContactForm"));
const CopyrightTakedownForm = loadable(() => import("./CopyrightTakedownForm"));
const Divider = loadable(() => import("./Divider"));
const EventButton = loadable(() => import("./Event/Button"));
const EventHeader = loadable(() => import("./Event/Header"));
const EventImage = loadable(() => import("./Event/Image"));
const EventLongDescription = loadable(() => import("./Event/LongDescription"));
const EventRelatedMovies = loadable(() => import("./Event/RelatedMovies"));
const EventShortDescription = loadable(
  () => import("./Event/ShortDescription")
);
const Events = loadable(() => import("./Events"));
const FreeText = loadable(() => import("./FreeText"));
const Gallery = loadable(() => import("./Gallery"));
const GiftCardBalanceChecker = loadable(
  () => import("./loyalty/GiftCardBalanceChecker")
);
const Heading = loadable(() => import("./Heading"));
const HeroCarousel = loadable(() => import("./HeroCarousel"));
const HeroImage = loadable(() => import("./HeroImage"));
const Iframe = loadable(() => import("./Iframe"));
const Image = loadable(() => import("./Image"));
const Localization = loadable(() => import("./Localization"));
const Locations = loadable(() => import("./Locations"));
const LoginForm = loadable(() => import("./loyalty/LoginForm"));
const MobileApps = loadable(() => import("./MobileApps"));
const Movies = loadable(() => import("./Movies"));
const Navigation = loadable(() => import("./Navigation"));
const NewsletterSubscriptionForm = loadable(
  () => import("./NewsletterSubscriptionForm")
);
const OrderHistory = loadable(() => import("./loyalty/OrderHistory"));
const Prices = loadable(() => import("./Prices"));
const Section = loadable(() => import("./Section"));
const LoyaltySwitcher = loadable(() => import("./loyalty/LoyaltySwitcher"));
const Showtimes = loadable(() => import("./Showtimes"));
const Signup = loadable(() => import("./loyalty/Signup"));
const Spacer = loadable(() => import("./Spacer"));
const TheaterInfo = loadable(() => import("./TheaterInfo"));
const TheaterPictures = loadable(() => import("./TheaterPictures"));
const TheaterSelector = loadable(() => import("./TheaterSelector"));
const UserProfile = loadable(() => import("./loyalty/UserProfile"));
const Video = loadable(() => import("./Video"));
const WelcomeMessage = loadable(() => import("./loyalty/WelcomeMessage"));

interface Props extends WidgetProps {
  widget: WidgetUnion;
}

const Widget: React.FC<Props> = ({
  widget,
  isFirst = false,
  isLast = false,
  isNested = false,
  shouldBleed = false,
  containerSize,
  prevWidget,
  nextWidget,
  data,
}) => {
  const commonProps = {
    isFirst,
    isLast,
    isNested,
    shouldBleed,
    containerSize,
    prevWidget,
    nextWidget,
  };

  switch (widget?.__typename) {
    /**
     * Basic widgets
     */
    case "WidgetAccordion":
      return <Accordion {...commonProps} widget={widget} />;
    case "WidgetHeading":
      return <Heading {...commonProps} widget={widget} />;
    case "WidgetButton":
      return <Button {...commonProps} widget={widget} />;
    case "WidgetCopyrightTakedownForm":
      return <CopyrightTakedownForm {...commonProps} widget={widget} />;
    case "WidgetFreeText":
      return <FreeText {...commonProps} widget={widget} />;
    case "WidgetMovies":
      return <Movies {...commonProps} widget={widget} />;
    case "WidgetDivider":
      return <Divider {...commonProps} widget={widget} />;
    case "WidgetEvents":
      return <Events {...commonProps} widget={widget} />;
    case "WidgetImage":
      return <Image {...commonProps} widget={widget} />;
    case "WidgetShowtimes":
      return <Showtimes {...commonProps} widget={widget} />;
    case "WidgetLocations":
      return <Locations {...commonProps} widget={widget} />;
    case "WidgetVideo":
      return <Video {...commonProps} widget={widget} />;
    case "WidgetHeroCarousel":
      return <HeroCarousel {...commonProps} widget={widget} />;
    case "WidgetHeroImage":
      return <HeroImage {...commonProps} widget={widget} />;
    case "WidgetSpacer":
      return <Spacer {...commonProps} widget={widget} />;
    case "WidgetMobileApps":
      return <MobileApps {...commonProps} widget={widget} />;
    case "WidgetGallery":
      return <Gallery {...commonProps} widget={widget} />;
    case "WidgetNavigation":
      return <Navigation {...commonProps} widget={widget} />;
    case "WidgetTheaterSelector":
      return <TheaterSelector {...commonProps} widget={widget} />;

    /**
     * Widget groups
     */
    case "WidgetColumns":
      return <Columns {...commonProps} widget={widget} data={data} />;
    case "WidgetSection":
      return <Section {...commonProps} widget={widget} data={data} />;
    case "WidgetLoyaltySwitcher":
      return <LoyaltySwitcher {...commonProps} widget={widget} data={data} />;

    /** Theater details widgets */
    case "WidgetTheaterPictures":
      return <TheaterPictures {...commonProps} widget={widget} />;
    case "WidgetTheaterInfo":
      return <TheaterInfo {...commonProps} widget={widget} />;
    case "WidgetAttributes":
      return <Attributes {...commonProps} widget={widget} data={data} />;
    case "WidgetLocalization":
      return <Localization {...commonProps} widget={widget} data={data} />;
    case "WidgetPrices":
      return <Prices {...commonProps} widget={widget} data={data} />;

    /**
     * Event details widgets
     */
    case "WidgetEventHeader":
      return <EventHeader {...commonProps} widget={widget} data={data} />;
    case "WidgetEventImage":
      return <EventImage {...commonProps} widget={widget} data={data} />;
    case "WidgetEventDescription":
      return (
        <EventShortDescription {...commonProps} widget={widget} data={data} />
      );
    case "WidgetEventLongDescription":
      return (
        <EventLongDescription {...commonProps} widget={widget} data={data} />
      );
    case "WidgetEventButton":
      return <EventButton {...commonProps} widget={widget} data={data} />;
    case "WidgetEventLinkedMovies":
      return (
        <EventRelatedMovies {...commonProps} widget={widget} data={data} />
      );

    /**
     * Loyalty widgets
     */
    case "WidgetGiftCardBalanceChecker":
      return <GiftCardBalanceChecker {...commonProps} widget={widget} />;
    case "WidgetLoginForm":
      return <LoginForm {...commonProps} widget={widget} />;
    case "WidgetOrderHistory":
      return <OrderHistory {...commonProps} widget={widget} />;
    case "WidgetWelcomeMessage":
      return <WelcomeMessage {...commonProps} widget={widget} />;
    case "WidgetSignup":
      return <Signup {...commonProps} widget={widget} />;
    case "WidgetUserProfile":
      return <UserProfile {...commonProps} widget={widget} />;

    /**
     * Misc Widgets
     */
    case "WidgetAccessibilityInfo":
      return <AccessibilityInfo {...commonProps} widget={widget} />;
    case "WidgetContactForm":
      return <ContactForm {...commonProps} widget={widget} />;
    case "WidgetNewsletterSubscriptionForm":
      return <NewsletterSubscriptionForm {...commonProps} widget={widget} />;
    case "WidgetIframe":
      return <Iframe {...commonProps} widget={widget} />;

    /**
     * Warn about missing widgets
     */
    default: {
      console.log(
        // @ts-expect-error keep this default case just in case
        `Unknown widget ${widget?.__typename} from id: ${widget?.id}`
      );
      return null;
    }
  }
};

export default memo(Widget);
