/** @jsx jsx */
import { ADS } from "gatsby-theme-ads";
import { Footer } from "gatsby-theme-footer";
import { Header } from "gatsby-theme-header";
import { SEO } from "gatsby-theme-seo";
import { ClosureMessage, EmergencyMessage } from "gatsby-theme-warning-message";
import React, { Fragment, memo } from "react";
import usePageContext from "shared/hooks/usePageContext";
import { jsx } from "theme-ui";

import type { PageQuery } from "../../../../__generated__/gatsby.types";
import Widget from "../../../components/Widget";
import type { WidgetUnion } from "../../../components/Widget/types";
import { Widgets } from "../types";

interface Props {
  data: PageQuery;
  widgets: Widgets;
}

const FALLBACK_WIDGETS: WidgetUnion[] = [
  {
    id: "0",
    __typename: "WidgetEventHeader",
  },
  {
    id: "1",
    __typename: "WidgetEventImage",
  },
  {
    id: "2",
    __typename: "WidgetEventLongDescription",
  },
  {
    id: "3",
    __typename: "WidgetEventButton",
  },
  {
    id: "4",
    __typename: "WidgetEventLinkedMovies",
  },
  {
    id: "5",
    eventsShape: {
      display: "FEATURED",
    },
    __typename: "WidgetEvents",
  },
];

const EventPage: React.FC<Props> = ({ data, widgets }) => {
  const { isSingleLocation } = usePageContext();

  const eventData = data.event;
  const _widgets = widgets || FALLBACK_WIDGETS;

  const hasBodyOnly = data.page?.hasBodyOnly ?? false;

  if (!eventData) {
    return null;
  }

  const title = data.event?.title || "";

  return (
    <div sx={{ variant: "siteWrapper" }}>
      <SEO title={title} />
      {!hasBodyOnly && (
        <Fragment>
          <ADS pageTitle={title} />
          <Header />
        </Fragment>
      )}

      <div sx={{ flex: 1 }}>
        <EmergencyMessage />
        {isSingleLocation && <ClosureMessage isSingleLocation withContainer />}

        <div sx={{ position: "relative", flex: 1 }}>
          <div sx={{ position: "relative" }}>
            {_widgets?.map((widget, index) =>
              widget ? (
                <Widget
                  key={index}
                  widget={widget}
                  isFirst={index === 0}
                  isLast={index === (_widgets?.length || 0) - 1}
                  isNested={false}
                  containerSize={"STANDARD"}
                  data={eventData}
                  prevWidget={_widgets[index - 1]}
                  nextWidget={_widgets[index + 1]}
                />
              ) : null
            )}
          </div>
        </div>
      </div>
      {!hasBodyOnly && <Footer />}
    </div>
  );
};

export default memo(EventPage);
