/** @jsx jsx */
import { graphql } from "gatsby";
import React, { memo, useState } from "react";
import { jsx } from "theme-ui";

import type { PageQuery } from "../../../__generated__/gatsby.types";
import usePortalRedirect from "../../hooks/usePortalRedirect";
import usePreview from "../../hooks/usePreview";
import EventPage from "./Event";
import StandardPage from "./Standard";
import TheaterPage from "./Theater";
import { Widgets } from "./types";

interface Props {
  data: PageQuery;
}

const Page: React.FC<Props> = ({ data }) => {
  const [widgets, setWidgets] = useState<Widgets>(data.page?.widgets);

  usePortalRedirect(data);

  usePreview(data.page?.id || null, data.page?.type || null, (nextWidgets) => {
    setWidgets(nextWidgets);
  });

  if (data.event || data.page?.type === "EVENT") {
    return <EventPage data={data} widgets={widgets} />;
  }

  if (data.theater || data.page?.type === "THEATER_INFO") {
    return <TheaterPage data={data} widgets={widgets} />;
  }

  return <StandardPage data={data} widgets={widgets} />;
};

export const query = graphql`
  fragment AllBasicWidgets on Widget {
    ...WidgetAccessibilityInfo
    ...WidgetAccordion
    ...WidgetAttributes
    ...WidgetButton
    ...WidgetContactForm
    ...WidgetCopyrightTakedownForm
    ...WidgetDivider
    ...WidgetEventButton
    ...WidgetEventDescription
    ...WidgetEventHeader
    ...WidgetEventImage
    ...WidgetEventLinkedMovies
    ...WidgetEventLongDescription
    ...WidgetEvents
    ...WidgetFreeText
    ...WidgetGallery
    ...WidgetGiftCardBalanceChecker
    ...WidgetHeading
    ...WidgetHeroCarousel
    ...WidgetHeroImage
    ...WidgetIframe
    ...WidgetImage
    ...WidgetLocalization
    ...WidgetLocations
    ...WidgetLoginForm
    ...WidgetMobileApps
    ...WidgetMovies
    ...WidgetNavigation
    ...WidgetNewsletterSubscriptionForm
    ...WidgetOrderHistory
    ...WidgetPrices
    ...WidgetShowtimes
    ...WidgetSignup
    ...WidgetSpacer
    ...WidgetTheaterInfo
    ...WidgetTheaterPictures
    ...WidgetTheaterSelector
    ...WidgetUserProfile
    ...WidgetVideo
    ...WidgetWelcomeMessage
  }

  # Section widget
  fragment WidgetSection on WidgetSection {
    id
    __typename
  }
  fragment WidgetSectionShape on WidgetSectionShape {
    border
    backgroundColor
    fullWidth
    wrapperSize
    containerSize
  }

  # Columns widget
  fragment WidgetColumns on WidgetColumns {
    id
    __typename
  }
  fragment WidgetColumnsShape on WidgetColumnsShape {
    border
    backgroundColor
    fullWidth
    wrapperSize
    containerSize
    columnSizes
  }

  # Loyalty Switcher widget
  fragment WidgetLoyaltySwitcher on WidgetLoyaltySwitcher {
    id
    __typename
  }

  # WidgetsRoot
  fragment WidgetsRoot on Widget {
    ...AllBasicWidgets
    ... on WidgetSection {
      ...WidgetSection
      sectionShape {
        ...WidgetSectionShape
        widgets {
          ...WidgetsDepth1
        }
      }
    }
    ... on WidgetColumns {
      ...WidgetColumns
      columnsShape {
        ...WidgetColumnsShape
        columns {
          widgets {
            ...WidgetsDepth1
          }
        }
      }
    }
    ... on WidgetLoyaltySwitcher {
      ...WidgetLoyaltySwitcher
      loyaltySwitcherShape {
        guest {
          ...WidgetsDepth1
        }
        signed {
          ...WidgetsDepth1
        }
      }
    }
  }

  # WidgetsDepth1
  fragment WidgetsDepth1 on Widget {
    ...AllBasicWidgets
    ... on WidgetSection {
      ...WidgetSection
      sectionShape {
        ...WidgetSectionShape
        widgets {
          ...WidgetsDepth2
        }
      }
    }
    ... on WidgetColumns {
      ...WidgetColumns
      columnsShape {
        ...WidgetColumnsShape
        columns {
          widgets {
            ...WidgetsDepth2
          }
        }
      }
    }
    ... on WidgetLoyaltySwitcher {
      ...WidgetLoyaltySwitcher
      loyaltySwitcherShape {
        guest {
          ...WidgetsDepth2
        }
        signed {
          ...WidgetsDepth2
        }
      }
    }
  }

  # WidgetsDepth2
  fragment WidgetsDepth2 on Widget {
    ...AllBasicWidgets
    ... on WidgetSection {
      ...WidgetSection
      sectionShape {
        ...WidgetSectionShape
        widgets {
          ...WidgetsDepth3
        }
      }
    }
    ... on WidgetColumns {
      ...WidgetColumns
      columnsShape {
        ...WidgetColumnsShape
        columns {
          widgets {
            ...WidgetsDepth3
          }
        }
      }
    }
    ... on WidgetLoyaltySwitcher {
      ...WidgetLoyaltySwitcher
      loyaltySwitcherShape {
        guest {
          ...WidgetsDepth3
        }
        signed {
          ...WidgetsDepth3
        }
      }
    }
  }

  # WidgetsDepth3
  fragment WidgetsDepth3 on Widget {
    ...AllBasicWidgets
    ... on WidgetSection {
      ...WidgetSection
      sectionShape {
        ...WidgetSectionShape
        widgets {
          ...AllBasicWidgets
          ...WidgetSection
          ...WidgetColumns
          ...WidgetLoyaltySwitcher
        }
      }
    }
    ... on WidgetColumns {
      ...WidgetColumns
      columnsShape {
        ...WidgetColumnsShape
        columns {
          widgets {
            ...AllBasicWidgets
            ...WidgetSection
            ...WidgetColumns
            ...WidgetLoyaltySwitcher
          }
        }
      }
    }
    ... on WidgetLoyaltySwitcher {
      ...WidgetLoyaltySwitcher
      loyaltySwitcherShape {
        guest {
          ...AllBasicWidgets
          ...WidgetSection
          ...WidgetColumns
          ...WidgetLoyaltySwitcher
        }
        signed {
          ...AllBasicWidgets
          ...WidgetSection
          ...WidgetColumns
          ...WidgetLoyaltySwitcher
        }
      }
    }
  }

  query Page($pageId: String, $eventId: String, $theaterId: String) {
    page(id: { eq: $pageId }) {
      id
      name
      type
      hasBodyOnly
      metaContent {
        title
        imageUrl
        description
      }
      parentType
      relatedEntity {
        id
      }
      childPages {
        slug
        relatedEntity {
          id
        }
      }
      parent {
        ... on Page {
          __typename
          slug
          parentType
          childPages {
            slug
            relatedEntity {
              id
            }
          }
        }
      }
      widgets {
        ...WidgetsRoot
      }
    }
    event(id: { eq: $eventId }) {
      id
      visibleStartAt
      visibleEndAt
      type
      title
      startAt
      shortDescription
      poster
      posterProbe {
        width
        height
        ratio
      }
      description
      endAt
      timeZone
      buttonLabel
      bookingUrl
      relatedMovies {
        movies {
          id
          title
          poster
          certificate
          runtime
          path
        }
        showtimesStartDate
        showtimesEndDate
      }
      theaters {
        id
        name
        timeZone
        showtimeWeekReleaseDay
      }
    }
    theater(id: { eq: $theaterId }) {
      id
      name
      # <> used by widget Attributes
      screens {
        number
        name
        flags {
          accessibility
          comfort
          experience
          food
          picture
          restriction
          service
          sound
        }
      }
      # </> used by widget Attributes
      practicalInfo {
        # <> used by widget Prices
        pricing {
          comment
          currency
          id
          price
          name
        }
        # </> used by widget Prices
        # <> used by widget Localization
        phone
        location {
          address
          city
          state
          zip
          country
        }
        coordinates {
          latitude
          longitude
        }
        # </> used by widget Localization
      }
    }
  }
`;

export default memo(Page);
