import { MessageDescriptor } from "shared/helpers/i18n/useIntl";

import { WidgetUnion } from "../../../components/Widget/types";
import messages from "./i18n";

export const getFallbackWidgets = (
  formatMessage: (message: MessageDescriptor) => string
): WidgetUnion[] => {
  const widgets: WidgetUnion[] = [
    {
      id: "0",
      __typename: "WidgetColumns",
      columnsShape: {
        border: false,
        backgroundColor: "highlight",
        fullWidth: false,
        wrapperSize: "FULL",
        containerSize: "STANDARD",
        columnSizes: [50, 50],
        columns: [
          {
            widgets: [
              {
                id: "1",
                __typename: "WidgetTheaterPictures",
              },
            ],
          },
          {
            widgets: [
              {
                id: "2",
                __typename: "WidgetTheaterInfo",
                theaterInfoShape: {
                  name: true,
                  description: true,
                  address: true,
                  email: true,
                  phone: true,
                  social: true,
                },
              },
            ],
          },
        ],
      },
    },
    {
      id: "3",
      __typename: "WidgetHeading",
      headingShape: {
        type: "h1",
        text: formatMessage(messages.availableFeatures),
        alignment: "center",
      },
    },
    {
      id: "4",
      attributesShape: {
        tags: [],
      },
      __typename: "WidgetAttributes",
    },
    {
      id: "5",
      __typename: "WidgetSpacer",
      spacerShape: {
        size: "MEDIUM",
      },
    },
    {
      id: "6",
      __typename: "WidgetSection",
      sectionShape: {
        border: false,
        backgroundColor: "transparent",
        fullWidth: false,
        wrapperSize: "NARROW",
        containerSize: "NARROW",
        widgets: [
          {
            id: "7",
            __typename: "WidgetHeading",
            headingShape: {
              type: "h1",
              text: formatMessage(messages.prices),
              alignment: "center",
            },
          },
          {
            id: "8",
            __typename: "WidgetPrices",
          },
        ],
      },
    },
    {
      id: "9",
      __typename: "WidgetSpacer",
      spacerShape: {
        size: "MEDIUM",
      },
    },
    {
      id: "10",
      __typename: "WidgetLocalization",
    },
  ];

  return widgets;
};
