import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  prices: {
    id: "theaters.theater.page.prices-title",
    defaultMessage: "Ticket prices",
  },
  availableFeatures: {
    id: "theaters.theater.page.features-title",
    defaultMessage: "Experiences and features at this theater",
  },
});

export default messages;
