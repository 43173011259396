/** @jsx jsx */
import { ADS } from "gatsby-theme-ads";
import { Footer } from "gatsby-theme-footer";
import { Header } from "gatsby-theme-header";
import { SEO } from "gatsby-theme-seo";
import { ClosureMessage, EmergencyMessage } from "gatsby-theme-warning-message";
import React, { Fragment, memo } from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import usePageContext from "shared/hooks/usePageContext";
import { jsx } from "theme-ui";

import type { PageQuery } from "../../../../__generated__/gatsby.types";
import Widget from "../../../components/Widget";
import { Widgets } from "../types";
import { getFallbackWidgets } from "./helpers";

interface Props {
  data: PageQuery;
  widgets: Widgets;
}

const TheaterPage: React.FC<Props> = ({ data, widgets }) => {
  const { isSingleLocation } = usePageContext();
  const { formatMessage } = useIntl();

  const theaterData = data.theater;
  const _widgets = widgets || getFallbackWidgets(formatMessage);

  const hasBodyOnly = data.page?.hasBodyOnly ?? false;

  if (!theaterData) {
    return null;
  }

  const title = data.theater?.name || "";

  return (
    <div sx={{ variant: "siteWrapper" }}>
      <SEO title={title} />
      {!hasBodyOnly && (
        <Fragment>
          <ADS pageTitle={title} />
          <Header />
        </Fragment>
      )}

      <div sx={{ flex: 1 }}>
        <EmergencyMessage />
        {isSingleLocation && <ClosureMessage isSingleLocation withContainer />}
        <div sx={{ position: "relative", flex: 1 }}>
          <div sx={{ position: "relative" }}>
            {_widgets?.map((widget, index) =>
              widget ? (
                <Widget
                  key={index}
                  widget={widget}
                  isFirst={index === 0}
                  isLast={index === (_widgets?.length || 0) - 1}
                  isNested={false}
                  containerSize={"STANDARD"}
                  data={theaterData}
                  prevWidget={_widgets[index - 1]}
                  nextWidget={_widgets[index + 1]}
                />
              ) : null
            )}
          </div>
        </div>
      </div>
      {!hasBodyOnly && <Footer />}
    </div>
  );
};

export default memo(TheaterPage);
