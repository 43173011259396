import { useEffect, useState } from "react";
import useNavigate from "shared/hooks/useNavigate";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";

import { PageQuery } from "../../../__generated__/gatsby.types";

const usePortalRedirect = (data: PageQuery): void => {
  const [selectedTheaterId, setSelectedTheaterId] = useSelectedTheaterId();
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const navigate = useNavigate();

  /**
   * In a theater portal context,
   * If the user lands on a child page,
   * The theater id becomes the selected theater id.
   * - this should only happen on mount
   */
  useEffect(() => {
    const parentPage =
      data.page?.parent?.__typename === "Page" ? data.page.parent : null;

    if (parentPage?.parentType === "THEATER" && data.page?.relatedEntity?.id) {
      setSelectedTheaterId(data.page?.relatedEntity?.id);
      setIsMounted(true);
    }
  }, []);

  /**
   * In a theater portal context,
   * When on a child page,
   * When changing the selected theater id (with a theater selector for example),
   * The user is redirected to the corresponding child page.
   * - this needs to happen only after the overriding hook above, we use the flag `isMounted` for that.
   */
  useEffect(() => {
    if (isMounted) {
      const parentPage =
        data.page?.parent?.__typename === "Page" ? data.page.parent : null;

      if (parentPage?.parentType === "THEATER") {
        /**
         * 'relatedPage' can be the target sibling page, or the parent page depending on the presence of the 'selectedTheaterId' value
         */
        const relatedPage = selectedTheaterId
          ? parentPage?.childPages?.find(
              (childPage) => childPage?.relatedEntity?.id === selectedTheaterId
            )
          : parentPage;

        if (relatedPage?.slug !== undefined) {
          navigate({ to: `/${relatedPage.slug}`, replace: true });
        }
      }
    }
  }, [selectedTheaterId, isMounted]);

  /**
   * In a theater portal context,
   * When landing on the portal page,
   * While having a selected theater id in the context,
   * The user is redirected to the corresponding child page.
   * /!\ this behaviour is disabled when the website has been opened in a popup (by the CMS for example).
   */
  useEffect(() => {
    if (data.page?.parentType === "THEATER" && window.opener) {
      setSelectedTheaterId("");
      return;
    }

    if (data.page?.parentType === "THEATER") {
      const relatedPage = data.page.childPages?.find(
        (childPage) => childPage?.relatedEntity?.id === selectedTheaterId
      );

      if (relatedPage?.slug) {
        navigate({ to: `/${relatedPage.slug}`, replace: true });
      }
    }
  }, [selectedTheaterId]);
};

export default usePortalRedirect;
