/** @jsx jsx */
import { ADS } from "gatsby-theme-ads";
import { Footer } from "gatsby-theme-footer";
import { Header } from "gatsby-theme-header";
import { SEO } from "gatsby-theme-seo";
import { ClosureMessage, EmergencyMessage } from "gatsby-theme-warning-message";
import React, { Fragment, memo } from "react";
import { withProtocol } from "shared/helpers/withProtocol";
import usePageContext from "shared/hooks/usePageContext";
import { jsx } from "theme-ui";

import type { PageQuery } from "../../../../__generated__/gatsby.types";
import Widget from "../../../components/Widget";
import { Widgets } from "../types";

interface Props {
  data: PageQuery;
  widgets: Widgets;
}

const StandardPage: React.FC<Props> = ({ data, widgets }) => {
  const { isSingleLocation } = usePageContext();

  const title = data.page?.name || "";
  const hasBodyOnly = data.page?.hasBodyOnly ?? false;

  return (
    <div sx={{ variant: "siteWrapper" }}>
      <SEO
        title={data.page?.metaContent?.title || title}
        description={data.page?.metaContent?.description || undefined}
        image={
          data.page?.metaContent?.imageUrl
            ? withProtocol(data.page?.metaContent?.imageUrl)
            : undefined
        }
      />

      {!hasBodyOnly && (
        <Fragment>
          <ADS pageTitle={title} />
          <Header />
        </Fragment>
      )}

      <div sx={{ flex: 1 }}>
        <EmergencyMessage />
        {isSingleLocation && <ClosureMessage isSingleLocation withContainer />}
        {widgets?.map((widget, index) =>
          widget ? (
            <Widget
              key={widget.id}
              widget={widget}
              isFirst={index === 0}
              isLast={index === (widgets?.length || 0) - 1}
              isNested={false}
              containerSize={"STANDARD"}
              prevWidget={widgets[index - 1]}
              nextWidget={widgets[index + 1]}
            />
          ) : null
        )}
      </div>
      {!hasBodyOnly && <Footer />}
    </div>
  );
};

export default memo(StandardPage);
