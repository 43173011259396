import { useLocation } from "@reach/router";
import { navigate as originalNavigate } from "gatsby";
import { parse, StringifiableRecord, stringifyUrl } from "query-string";

type NavigateProps<T> = {
  to?: string;
  routeQuery?: T;
  replace?: boolean;
};
type Navigate<T> = (props: NavigateProps<T>) => Promise<void>;

const useNavigate = <T extends StringifiableRecord>(): Navigate<T> => {
  const { search, pathname } = useLocation();

  const navigate: Navigate<T> = ({
    to,
    routeQuery,
    replace = false,
  }): Promise<void> => {
    const enhancedTo = stringifyUrl(
      {
        url: to || pathname,
        query: { ...parse(search, { decode: true }), ...routeQuery },
      },
      { encode: true }
    );
    return originalNavigate(enhancedTo, { replace });
  };

  return navigate;
};

export default useNavigate;
