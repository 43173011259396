import { useEffect } from "react";

import { PageType } from "../../../__generated__/gatsby.types";
import {
  LifeCycleMessageData,
  Widgets,
  WidgetsMessageData,
} from "../../templates/Page/types";

const usePreview = (
  pageId: string | null,
  pageType: PageType | null,
  onWidgetsReceived: (widgets: Widgets) => void
): void => {
  const receiveMessage = (
    message: MessageEvent<WidgetsMessageData | null | undefined>
  ) => {
    /**
     * Conditions to react to a post message:
     * - Is it a widget payload?
     * -- Is it the target page or the default cms-preview page?
     * -- Is it the default 404 page?
     *
     * Note: A "new page" payload comes with a null ID and the "cms-preview" page has a null ID
     */
    if (
      message.data?.type === "widgets" &&
      (message.data?.pageId === (pageId || null) || pageType === "HTTP_404")
    ) {
      const nextWidgets = message.data.widgets;
      onWidgetsReceived(nextWidgets);
    }
  };

  useEffect(() => {
    window.addEventListener("message", receiveMessage);

    if (window.opener) {
      const lifeCyclePayload: LifeCycleMessageData = {
        type: "lifeCycle",
        lifeCycle: "mounted",
      };

      window.opener.postMessage(lifeCyclePayload, "*");
    }

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);
};

export default usePreview;
